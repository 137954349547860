import React, { FC, Fragment, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import ICONS from 'components/Icons';
import RichTextInput, { RichTextInputMethods } from 'components/RichTextInput';
import ToggleSwitch from 'components/ToggleSwitch';
import { PriceSettings } from 'api/data/pages/types';
import { FeePayer } from 'api/data/user/types';
import './style.scss';

type PricingSettingsContentProps = {
  page: {
    processingFeePayer?: FeePayer;
    tipsEnabled?: boolean;
    acceptOfflinePayments?: boolean;
    offlinePaymentInstructions?: string;
  };
  onSave: (attrs: PriceSettings) => void;
  showCashOrCheck?: boolean;
  loading?: boolean;
  onClose?: () => void;
};

const payerPageOptionsDescriptions = {
  PAYER: 'Payers will be charged for card processing or can choose to pay with ACH Bank transfer for free.',
  ORGANIZATION: 'Your organization will cover card processing so that payers are not charged a fee.',
};

const tipsPageOptionsDescriptions = {
  ON: 'Payers will be asked to leave an optional tip, which means your organization will not be charged a platform fee.',
  OFF: 'Payers will not be asked to leave an optional tip. Instead, your organization will be charged a platform fee per payment.',
};

const PricingSettingsContent: FC<PricingSettingsContentProps> = ({
  page,
  onSave,
  showCashOrCheck = false,
  loading = false,
  onClose,
}) => {
  const richTextBodyInputRef = useRef<RichTextInputMethods>(null);
  const copySettings = {
    acceptOfflinePayments: page.acceptOfflinePayments,
    processingFeePayer: page.processingFeePayer,
    tipsEnabled: page.tipsEnabled,
    offlinePaymentInstructions: page.offlinePaymentInstructions,
  };
  const [settings, setSettings] = useState({ ...copySettings });
  const [showError, setShowError] = useState(false);

  const handleResetClick = () => {
    if (richTextBodyInputRef.current) {
      richTextBodyInputRef.current.reset();
    }

    setSettings({ ...copySettings });
    onClose?.();
  };

  const onChange = (attrs: PriceSettings) => {
    setSettings({ ...settings, ...attrs });
  };

  const handleSubmit = () => {
    setShowError(false);
    if (showCashOrCheck && settings.acceptOfflinePayments && !settings.offlinePaymentInstructions) setShowError(true);
    else
      onSave({
        ...settings,
        offlinePaymentInstructions: settings.offlinePaymentInstructions
          ? DOMPurify.sanitize(settings.offlinePaymentInstructions)
          : settings.offlinePaymentInstructions,
      });
  };

  return (
    <>
      <div className="settings-content">
        <div className="row content-header">
          {ICONS['fees_money_hand']}
          <p className="title">Card Processing Fee</p>
        </div>
        <p>You&apos;re in control: as an organization, you choose who covers card processing fees.</p>
        <div className="payer-options">
          {[
            {
              payer: 'PAYER' as FeePayer,
              label: 'Payers',
            },
            {
              payer: 'ORGANIZATION' as FeePayer,
              label: 'Your organization',
            },
          ].map(element => (
            <Fragment key={element.payer}>
              <input
                type="radio"
                id={`processingFeePayer-${element.payer}`}
                name={`processingFeePayer-${element.payer}`}
                checked={settings.processingFeePayer === element.payer}
                onChange={() => onChange({ processingFeePayer: element.payer })}
              />
              <label htmlFor={`processingFeePayer-${element.payer}`}>{element.label}</label>
            </Fragment>
          ))}
        </div>
        <p className="callout">
          {ICONS['solid_info']}
          {settings.processingFeePayer && payerPageOptionsDescriptions[settings.processingFeePayer]}
        </p>
      </div>
      <div className="settings-content">
        <div className="row space-between content-header">
          <div className="row">
            {ICONS['pig']}
            <p className="title">Tips</p>
          </div>
          <ToggleSwitch
            toggleValue={settings.tipsEnabled || false}
            ariaLabel="tips on"
            label={settings.tipsEnabled ? 'On' : 'Off'}
            labelPosition="after"
            handleOnChange={() => onChange({ tipsEnabled: !settings.tipsEnabled })}
          />
        </div>
        <p>{tipsPageOptionsDescriptions[settings.tipsEnabled ? 'ON' : 'OFF']}</p>
      </div>
      {showCashOrCheck && (
        <>
          {' '}
          <div className="settings-content">
            <div className="row space-between">
              <div className="row">
                {ICONS['cash']}
                <p className="title">Accept Cash or Check Payments</p>
              </div>
              <ToggleSwitch
                toggleValue={settings.acceptOfflinePayments || false}
                ariaLabel="cash or check payments"
                label={settings.acceptOfflinePayments ? 'On' : 'Off'}
                labelPosition="after"
                handleOnChange={() =>
                  onChange({
                    acceptOfflinePayments: !settings.acceptOfflinePayments,
                    offlinePaymentInstructions: settings.acceptOfflinePayments
                      ? ''
                      : settings.offlinePaymentInstructions,
                  })
                }
              />
            </div>
            {settings.acceptOfflinePayments && (
              <>
                <p className="callout cash-or-check">
                  {ICONS['solid_info']}
                  Note that this payment method is not available for subscriptions.
                </p>
                <RichTextInput
                  label="Add Instructions"
                  rules={{
                    required: true,
                    hasError: showError && !settings.offlinePaymentInstructions,
                    errorMessage: 'This field is required',
                  }}
                  ref={richTextBodyInputRef}
                  parseToHTMLCallback={(htmlFragment: string) => {
                    onChange({ offlinePaymentInstructions: htmlFragment });
                  }}
                  initialValue={page.offlinePaymentInstructions || ''}
                  name="instructions"
                  placeholder="e.g. Please make checks payable to..."
                />
              </>
            )}
          </div>
          <div className="bottom-content">
            <a href="https://www.omella.com/blog/pricing" target="_blank" rel="noreferrer">
              Learn more about pricing
            </a>
          </div>
        </>
      )}
      <footer className="actions-container-footer">
        <button disabled={loading} type="button" className="button-outline" onClick={handleResetClick}>
          {onClose ? 'Close' : 'Not now'}
        </button>
        <button onClick={handleSubmit} className="button primary" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </footer>
    </>
  );
};

export default PricingSettingsContent;
