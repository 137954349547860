import React, { SyntheticEvent, useRef, useState } from 'react';
import ICONS from '../Icons';
import Modal from '../Modal';
import Preview from './Preview';
import Tooltip from '../Tooltip';
import RichTextInput, { RichTextInputMethods } from '../RichTextInput';
import { Organization } from '~/api/data/user/types';
import './style.scss';

interface OrganizationReceiptProps {
  organization: Organization;
  loading: boolean;
  onSave: (receiptBody: string, receiptFooter: string) => void;
}

export default function OrganizationReceipt({ organization, loading, onSave }: OrganizationReceiptProps) {
  const [receiptBody, setReceiptBody] = useState(organization?.customBodyReceiptContent || '');
  const [receiptFooter, setReceiptFooter] = useState(organization?.customFooterReceiptContent || '');

  const [showModal, setShowModal] = useState(false);
  const richTextBodyInputRef = useRef<RichTextInputMethods>(null);
  const richTextFooterInputRef = useRef<RichTextInputMethods>(null);

  const handleResetClick = () => {
    if (richTextBodyInputRef.current) {
      richTextBodyInputRef.current.reset();
    }

    if (richTextFooterInputRef.current) {
      richTextFooterInputRef.current.reset();
    }
  };

  const handleSubmit = () => {
    setShowModal(false);
    onSave(receiptBody, receiptFooter);
  };

  return (
    <>
      <form
        className="receipt-settings"
        onSubmit={(event: SyntheticEvent) => {
          event.preventDefault();
          setShowModal(true);
        }}
        aria-label="receipt form">
        <div className="content">
          <div>
            <div className="header-container">
              <h2>Email receipts</h2>
              <Tooltip
                title="You can also customize receipt content at the page level by navigating to the page and clicking settings."
                color="dark"
                ariaLabel="receipt-info">
                {ICONS['solid_help']}
              </Tooltip>
            </div>
            <p className="title-description">Customize receipt content globally across all of your Omella pages.</p>
            <div className="input first">
              <RichTextInput
                name="body"
                ref={richTextBodyInputRef}
                id="body"
                placeholder="Please keep this receipt for your records."
                parseToHTMLCallback={(htmlFragment: string) => {
                  setReceiptBody(htmlFragment);
                }}
                initialValue={organization?.customBodyReceiptContent}
                label="Body"
                rules={{
                  required: true,
                  errorMessage: 'This field is required',
                  hasError: !receiptBody,
                }}
              />
            </div>
            <div>
              <RichTextInput
                name="footer"
                label="Footer"
                ref={richTextFooterInputRef}
                initialValue={organization?.customFooterReceiptContent}
                id="footer"
                placeholder="E.g. [org name] is a registered 501(c)(3) nonprofit organization, EIN: 12-2346789"
                parseToHTMLCallback={(htmlFragment: string) => setReceiptFooter(htmlFragment)}
              />
            </div>
          </div>
          <Preview subject={organization} customBodyPreview={receiptBody} customFooterPreview={receiptFooter} />
        </div>
        <div className="actions-container-footer">
          <button disabled={loading} type="button" className="button-outline" onClick={handleResetClick}>
            Not now
          </button>
          <button type="submit" className="button primary" disabled={loading || !receiptBody}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
      <Modal
        handleOnCancel={() => {
          setShowModal(false);
        }}
        handleOnConfirm={handleSubmit}
        confirmlabel="Update"
        denyLabel="Cancel"
        header="Update receipt content"
        headerIcon="warning"
        visible={showModal}
        className="warning-modal">
        <p>This will apply to all receipts except for those that have been customized on a specific page.</p>
        <b>Are you sure you want to update your receipt content?</b>
      </Modal>
    </>
  );
}
