import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useRoute } from 'wouter';
import * as PageQuery from 'graphql/page.graphql';
import { defaultValidAnswerPlaceholder } from 'defaults/page';
import { showPlural, validateEmail } from 'utils';
import Message from 'components/Message';
import PasscodeList from './PasscodeList';
import SideModal from 'components/SideModal';
import SideModalHeader from 'components/SideModal/Header';
import TextArea from 'components/Input/TextArea';
import ToggleSwitch from '~/components/ToggleSwitch';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import { PasscodeList as PasscodeListType, Passcode } from 'api/data/pages/types';
import './style.scss';

interface AnsweValidationProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  hasValidationList: boolean;
  blockId: string;
  isEmailValidation?: boolean;
  setHasValidationList: (hasValidationList: boolean) => void;
}

const mapPasscodes = (passcodes: string, isEmailValidation?: boolean) => {
  return Array.from(
    new Set(
      passcodes
        .split(/[\n,; ]+/)
        .map(code => code.trim())
        .filter(code => code && (!isEmailValidation || validateEmail(code))),
    ),
  ).map(passcode => ({ passcode }));
};

const AnswerValidation: FC<AnsweValidationProps> = ({
  isOpen,
  setIsOpen,
  hasValidationList,
  blockId,
  setHasValidationList,
  isEmailValidation,
}) => {
  const [answers, setAnswers] = useState('');
  const [message, setMessage] = useState('');
  const [passcodeList, setPasscodeList] = useState<PasscodeListType | null>(null);
  const [viewMode, setViewMode] = useState('list');
  const [showMessage, setShowMessage] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [, params] = useRoute('/:currentSpace/pages/:id/edit');

  const { data: passcodeLists, refetch: refetchLists } = useQuery<{ passcodeLists: PasscodeListType[] }>(
    PageQuery.GetPasscodeLists,
    {
      variables: { questionBlockId: blockId, pageId: params?.id },
      fetchPolicy: 'network-only',
    },
  );

  const [savePassCodeList, { loading }] = useMutation(PageQuery.SavePasscodeList);

  const allPasscodes =
    passcodeLists?.passcodeLists?.flatMap(
      passcodeList =>
        passcodeList.passcodes?.map(({ id, passcode, uses, maxUses }) => ({
          id,
          passcode,
          uses,
          maxUses,
        })) || [],
    ) || [];

  const cleanAndClose = () => {
    setAnswers('');
    setIsOpen(false);
  };

  const handleAdd = async () => {
    const answersMapped = mapPasscodes(answers, isEmailValidation);

    await savePassCodeList({
      variables: {
        passcodeList: {
          id: passcodeList?.id,
          passcodes: answersMapped.concat(passcodeList?.passcodes || []),
          questionBlockId: blockId,
        },
        pageId: params?.id,
      },
    });

    void refetchLists();
    handleSavePasscodeCallback(`${answersMapped.length} valid answer${showPlural(answersMapped.length)} added!`);
    setHasValidationList(true);
    setAnswers('');
    setViewMode('list');
  };

  const handleCloseBtn = () => {
    if (viewMode === 'form') return setViewMode('list');

    if (answers.trim()) setShowUnsavedChangesModal(true);
    else {
      cleanAndClose();
    }
  };

  const handleSavePasscodeCallback = (message: string) => {
    setMessage(message);
    setShowMessage(true);
  };

  const handleAddPasscode = (passcodeListId: string, passcodes: Passcode[]) => {
    setViewMode('form');
    setPasscodeList({ id: passcodeListId, passcodes, questionBlocks: [] });
  };

  const hasPasscodes = allPasscodes.length > 0;

  return (
    <>
      <UnsavedChangesModal
        setShow={setShowUnsavedChangesModal}
        show={showUnsavedChangesModal}
        onConfirmCallback={() => {
          void handleAdd();
          setShowUnsavedChangesModal(false);
        }}
        onCancelCallback={() => {
          setShowUnsavedChangesModal(false);
          cleanAndClose();
        }}
      />
      <SideModal
        isOpen={isOpen}
        toggle={setIsOpen}
        header={<SideModalHeader title="Answer Validation" onClose={handleCloseBtn} />}
        closeOnOutsideClick={false}
        className="side-modal-answer-validation">
        <Message type="succeeded" showMessage={showMessage} setShowMessage={visible => setShowMessage(visible)}>
          {message}
        </Message>
        <div className="answer-valiation-container">
          <ToggleSwitch
            label={hasValidationList ? 'Enabled' : 'Disabled'}
            labelPosition="after"
            toggleValue={hasValidationList}
            handleOnChange={() => setHasValidationList(!hasValidationList && hasPasscodes)}
          />
          {hasPasscodes && viewMode === 'list' ? (
            <div className="validation-container">
              <PasscodeList
                pageId={params?.id}
                data={passcodeLists?.passcodeLists}
                refetchLists={() => void refetchLists()}
                savePasscodeCallback={handleSavePasscodeCallback}
                blockId={blockId}
                handleAdd={handleAddPasscode}
                setHasValidationList={setHasValidationList}
              />
            </div>
          ) : (
            <div className="safe-space-footer">
              <div className="validation-container">
                <TextArea
                  name="valid answers"
                  label={`Type or paste each valid ${isEmailValidation ? 'email' : 'answer'}`}
                  value={answers}
                  onChange={({ target }) => setAnswers(target.value)}
                  rules={{ required: true }}
                  placeholder={defaultValidAnswerPlaceholder(isEmailValidation)}
                />
              </div>
              <div className="actions-container-footer">
                <button disabled={loading} type="button" className="button-outline" onClick={handleCloseBtn}>
                  {viewMode === 'form' ? 'Back' : 'Close'}
                </button>
                <button
                  className="button primary"
                  disabled={loading || !answers.trim()}
                  onClick={() => void handleAdd()}>
                  {loading ? 'Adding...' : 'Add'}
                </button>
              </div>
            </div>
          )}
        </div>
      </SideModal>
    </>
  );
};

export default AnswerValidation;
