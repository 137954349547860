import React, { FC } from 'react';

type CustomStyleProps = {
  style?: string;
};

const CustomStyle: FC<CustomStyleProps> = ({ style }) => {
  if (style) return <style data-testid="custom-style">{style}</style>;

  return null;
};

export default CustomStyle;
