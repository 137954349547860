import React, { SyntheticEvent, useEffect, useState } from 'react';
import { lowerCase, validateEmail } from 'utils';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import Input from 'components/Input';
import { SettingsProps } from 'pages/Settings';
import { Organization } from 'api/data/user/types';
import './style.scss';

const GeneralSettings = ({ handleSaveOrg, loading }: SettingsProps) => {
  const { currentOrg } = useCurrentOrganization();
  const [organization, setOrganization] = useState({
    name: '',
    contactName: '',
    contactEmail: '',
  });

  useEffect(() => {
    if (currentOrg) setOrganizationValues(currentOrg);
  }, [currentOrg]);

  const handleSubmit = (event: SyntheticEvent) => {
    event?.preventDefault();
    handleSaveOrg(organization);
  };

  const handleOnInputChange = (label: string, value: string) => {
    setOrganization({ ...organization, [label]: value });
  };

  const setOrganizationValues = (org: Organization) => {
    setOrganization({
      name: org.name,
      contactName: org.contactName,
      contactEmail: org.contactEmail,
    });
  };

  const invalidEmail = !!organization.contactEmail && !validateEmail(organization.contactEmail);
  const disableButton = Boolean(
    organization.name?.trim() === '' ||
      organization.contactEmail?.trim() === '' ||
      organization.contactName?.trim() === '' ||
      invalidEmail ||
      loading,
  );

  const fieldRules = {
    required: true,
    errorMessage: 'This is required',
  };

  return (
    <form className="general-settings" onSubmit={handleSubmit} aria-label="general settings form">
      <div className="content">
        <h2>Organization Info</h2>
        <p className="title-description">
          The Organization info below will be used in email receipts and appear in payment statement descriptors.
        </p>
        <Input
          label="Organization Name"
          placeholder="Enter organization name"
          onChange={({ target }) => handleOnInputChange('name', target.value)}
          value={organization.name}
          name="organization-name-input"
          rules={{
            ...fieldRules,
            hasError: !organization.name,
          }}
        />
        <div className="row contact-info">
          <Input
            label="Contact Name"
            placeholder="Enter contact name"
            onChange={({ target }) => handleOnInputChange('contactName', target.value)}
            value={organization.contactName}
            name="contact-name-input"
            rules={{
              ...fieldRules,
              hasError: !organization.contactName,
            }}
          />
          <Input
            className="contact-email-container"
            label="Contact Email"
            placeholder="Enter contact email"
            onChange={({ target }) => handleOnInputChange('contactEmail', lowerCase(target.value))}
            value={organization.contactEmail}
            name="contact-email-input"
            rules={{
              ...fieldRules,
              errorMessage: !organization.contactEmail ? fieldRules.errorMessage : 'Please enter a valid email.',
              hasError: !organization.contactEmail || invalidEmail,
            }}
            hint="This will be the primary contact email to this organization"
          />
        </div>
      </div>
      <div className="actions-container-footer">
        <button
          type="button"
          className="button-outline"
          onClick={() => currentOrg && setOrganizationValues(currentOrg)}>
          Cancel
        </button>
        <button type="submit" className="primary " disabled={disableButton}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  );
};

export default GeneralSettings;
