import React, { FC, Fragment } from 'react';
import useToggle from 'hooks/useToggle';
import { isColumnVisible } from './isColumnVisible';
import IconButton from 'components/Button/IconButton';
import SideModal from 'components/SideModal';
import SideModalHeader from 'components/SideModal/Header';
import { SubmissionQuestion } from 'api/data/response/types';
import { ColumnsProps, VisibleColumnsType } from '../types';
import './style.scss';

const Columns: FC<ColumnsProps> = ({ questions, visibleColumns, setVisibleColumns, fixedColumns }) => {
  const [toggleSideModal, setToggleSideModal] = useToggle(false);

  const handleOnChange = (checked: boolean, id: string) => {
    setVisibleColumns({ ...visibleColumns, [id]: checked });
  };

  const handleOnChangeField = (checked: boolean, id: string, page: SubmissionQuestion) => {
    const columnsToUpdate = { ...visibleColumns, [id]: checked };

    if (checked) {
      if (!visibleColumns[page.page_id]) {
        columnsToUpdate[page.page_id] = checked;
      }
    } else {
      const hasQuestion = page.questions.some(question => isColumnVisible(columnsToUpdate, question.block_id));
      const hasActivities = page.response_activity_types.some(type => isColumnVisible(columnsToUpdate, type.id));

      if (!hasQuestion && !hasActivities) {
        columnsToUpdate[page.page_id] = checked;
      }
    }

    setVisibleColumns(columnsToUpdate);
  };

  const handleOnChangePage = (checked: boolean, page: SubmissionQuestion) => {
    let columnsToUpdate = page.questions.reduce((obj, question) => {
      obj[question.block_id] = checked;
      return obj;
    }, {} as VisibleColumnsType);

    columnsToUpdate = page.response_activity_types.reduce((obj, type) => {
      obj[type.id] = checked;
      return obj;
    }, columnsToUpdate);

    setVisibleColumns({ ...visibleColumns, ...columnsToUpdate, [page.page_id]: checked });
  };

  return (
    <div className="columns-filter">
      <IconButton icon="column" onClick={() => setToggleSideModal(true)}>
        <span>Columns</span>
      </IconButton>
      <SideModal
        isOpen={toggleSideModal}
        toggle={setToggleSideModal}
        className="columns-side-modal"
        header={<SideModalHeader title="Columns" onClose={() => setToggleSideModal(false)} />}>
        <div className="safe-space-footer">
          {fixedColumns.map(column => (
            <Fragment key={column.id}>
              <label htmlFor={column.id}>
                {column.label}
                <input
                  type="checkbox"
                  id={column.id}
                  checked={isColumnVisible(visibleColumns, column.fieldName)}
                  onChange={({ target }) => handleOnChange(target.checked, column.fieldName)}
                />
              </label>
              <div className="horizontal-line"></div>
            </Fragment>
          ))}
          {questions.map(page => {
            return (
              <div key={page.page_id}>
                <label htmlFor={page.page_id}>
                  {page.page_title}
                  <input
                    type="checkbox"
                    id={page.page_id}
                    checked={isColumnVisible(visibleColumns, page.page_id)}
                    onChange={({ target }) => handleOnChangePage(target.checked, page)}
                  />
                </label>
                <div className="horizontal-line"></div>
                {page.questions.map(question => (
                  <Fragment key={question.block_id}>
                    <label htmlFor={question.block_id} className="page-fields">
                      {question.question_prompt}
                      <input
                        type="checkbox"
                        id={question.block_id}
                        checked={isColumnVisible(visibleColumns, question.block_id)}
                        onChange={({ target }) => handleOnChangeField(target.checked, question.block_id, page)}
                      />
                    </label>
                    <div className="horizontal-line"></div>
                  </Fragment>
                ))}
                {page.response_activity_types.map(activity_type => (
                  <Fragment key={activity_type.id}>
                    <label htmlFor={activity_type.id} className="page-fields">
                      {activity_type.name}
                      <input
                        type="checkbox"
                        id={activity_type.id}
                        checked={isColumnVisible(visibleColumns, activity_type.id)}
                        onChange={({ target }) => handleOnChangeField(target.checked, activity_type.id, page)}
                      />
                    </label>
                    <div className="horizontal-line"></div>
                  </Fragment>
                ))}
              </div>
            );
          })}
        </div>
      </SideModal>
    </div>
  );
};

export default Columns;
